import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLoginUserMutation } from "../../store/auth/authapi";
import { setAuth } from "../../store/auth/authSlice";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Typography,
} from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { getUser } from "../../auth/authutils";
import { REACT_APP_CRYPTO_SECRET_KEY } from "../../config/config";
import CryptoJS from "crypto-js";

const { Title } = Typography;

function Login() {
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [loginUser] = useLoginUserMutation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    handleAddUser(values);
  };

  const encryptPassword = (password) => {
    return CryptoJS.AES.encrypt(
      password,
      REACT_APP_CRYPTO_SECRET_KEY
    ).toString();
  };

  const handleAddUser = async (user) => {
    try {
      setLoading(true);
      const encryptedPassword = encryptPassword(user.password);
      const res = await loginUser({
        ...user,
        password: encryptedPassword,
      }).unwrap();
      const token = res.jwt.replace("Bearer ", "");
      const decoded = jwt_decode(token);
      const authObjWithToken = { token: token };
      dispatch(setAuth(authObjWithToken));

      const userDetails = await getUser(decoded.userId);
      const authObj = {
        token: token,
        userid: decoded.userId,
        baddress: userDetails.baddress,
        groups: userDetails.group,
        org: userDetails.org,
      };

      dispatch(setAuth(authObj));
      localStorage.setItem("jwt", res.jwt);
      localStorage.setItem("user", JSON.stringify(userDetails));

      message.success("Logged In!");

      setLoading(false);

      // Handle redirection based on userOrgName
      if (userDetails.org) {
        history.push("/intervention/list");
      } else {
        history.push("/contact-admin");
      }
    } catch (err) {
      console.error(err);
      setIsError(true);
      setErrorMsg(err.data?.message || "An error occurred");
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Card
        className="shadow-card"
        style={{ width: "35vw", marginTop: 70, position: "relative" }}
      >
        {loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1,
            }}
          >
            <Spin size="large" />
          </div>
        )}

        {isError && (
          <Alert
            message={errorMsg ? errorMsg : ""}
            description="Failed to log in with the combination of E-mail and password"
            type="error"
            showIcon
            banner
            closeIcon={<CloseCircleOutlined />}
            closable
            style={{ marginBottom: "20px" }}
            onClose={() => setIsError(false)}
          />
        )}

        <Row>
          <Col span={2}></Col>
          <Col span={20}>
            <Title level={1} style={{ textAlign: "center", fontWeight: 600 }}>
              Login
            </Title>
            <br />
            <Form
              layout="vertical"
              form={form}
              name="login"
              onFinish={onFinish}
              scrollToFirstError
            >
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: "email",
                    message: "The input is not a valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
              <div style={{ textAlign: "end", marginTop: -16 }}>
                <Button
                  type="link"
                  size="small"
                  style={{ color: "#199EA9" }}
                  onClick={() => history.push("/forgot-password")}
                >
                  Forgot your password?
                </Button>
              </div>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={loading}>
                  Login
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={2}></Col>
        </Row>
      </Card>
    </div>
  );
}

export default Login;

import { React, useState } from "react";
import { useSelector } from "react-redux";
import {
  useDeleteUserMutation,
  useGetUsersQuery,
  useUpdateUserMutation,
} from "../../store/auth/userapi";
import { CheckCircleOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Card,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Table,
  Typography,
  message,
  Button,
  Space,
} from "antd";

import { DeleteOutlined, EditOutlined, CopyOutlined } from "@ant-design/icons";

import moment from "moment";
import { useTransactionHandlers } from "../../bc-client/useTransactionHandlers";
import {
  grantProbaRoleAction,
  revokeProbaRoleAction,
} from "../../constants/actionNames";
import { useGetGroupsQuery } from "../../store/auth/groupapi";
import { useGetOrgsQuery } from "../../store/auth/orgapi";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
};

function User() {
  const [isEditing, setIsEditing] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [showLinkUserModal, setShowLinkUserModal] = useState(false);

  const userGroup = useSelector((state) =>
    state.auth?.groups?.map((group) => group)
  );
  const userOrgId = useSelector((state) => state.auth?.org?._id);
  const userOrgName = useSelector((state) => state.auth?.org?.name);
  const userBcAddress = useSelector((state) => state.auth?.baddress);
  const userGroupName = useSelector((state) =>
    state.auth.groups?.map((group) => group?.name)
  );
  const isAdmin = userGroupName.includes("Admin");

  //********bc-client starts */
  const { onAccept: onAcceptGrantProbaRoleAction } = useTransactionHandlers({
    actionname: grantProbaRoleAction.name,
  });
  const grantProbaActionInBC = async (payload) => {
    return await onAcceptGrantProbaRoleAction(payload);
  };

  const { onAccept: onAcceptRevokeProbaRoleAction } = useTransactionHandlers({
    actionname: revokeProbaRoleAction.name,
  });
  const revokeProbaRoleActionInBC = async (payload) => {
    return await onAcceptRevokeProbaRoleAction(payload);
  };
  //********bc-client ends */

  const { data: allgroupList, isLoading: isgroupLoading } = useGetGroupsQuery();
  const { data: orgdata, isLoading: isorgLoading } = useGetOrgsQuery();

  const handleCopyAddress = (address) => {
    navigator.clipboard.writeText(address);
    message.success("Blockchain address copied to clipboard!");
  };

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e?.target?.value ? [e?.target?.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => {clearFilters();confirm();}} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      if (dataIndex === 'org') {
        const orgDetails = orgdata?.orgList?.find((item) => item?._id === record?.org);
        return orgDetails?.name?.toLowerCase().includes(value?.toLowerCase());
      }
      return record[dataIndex]?.toString()?.toLowerCase()?.includes(value?.toLowerCase());
    },
  });

  const columns = [
    {
      key: "fname",
      title: "First Name",
      dataIndex: "fname",
      sorter: (a, b) => a?.fname?.localeCompare(b?.fname),
      ...getColumnSearchProps('fname', 'First Name'),
    },
    {
      key: "lname",
      title: "Last Name",
      dataIndex: "lname",
      sorter: (a, b) => a?.lname?.localeCompare(b?.lname),
      ...getColumnSearchProps('lname', 'Last Name'),
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      ...getColumnSearchProps('email', 'Email'),
    },
    {
      key: "org",
      title: "Organization",
      dataIndex: "org",
      sorter: (a, b) => {
        const orgA = orgdata?.orgList?.find((item) => item?._id === a?.org)?.name || '';
        const orgB = orgdata?.orgList?.find((item) => item?._id === b?.org)?.name || '';
        return orgA?.localeCompare(orgB);
      },
      render: (org) => {
        if (isorgLoading) {
          return <span>Loading...</span>;
        }
        const orgDetails = orgdata?.orgList?.find((item) => item?._id === org);
        return <span>{orgDetails?.name || ""}</span>;
      },
      ...getColumnSearchProps('org', 'Organization'),
    },
    {
      key: "group",
      title: "Group",
      dataIndex: "group",
      width: 250,
      render: (group) => {
        const groupNames = group.map((groupId) => {
          const foundGroup = allgroupList?.find((item) => item._id === groupId);
          return foundGroup?.name;
        });
        return <span>{groupNames.join(", ")}</span>;
      },
    },
    {
      key: "baddress",
      title: "Blockchain Address",
      dataIndex: "baddress",
      sorter: (a, b) => a?.baddress?.localeCompare(b?.baddress),
      render: (baddress) => {
        const truncatedAddress = `${baddress?.slice(0, 8)}...${baddress?.slice(
          -8
        )}`;
        return (
          <span>
            {truncatedAddress}
            <CopyOutlined
              style={{ marginLeft: 8 }}
              onClick={() => handleCopyAddress(baddress)}
            />
          </span>
        );
      },
      ...getColumnSearchProps('baddress', 'Blockchain Address'),
    },
    {
      key: "created",
      title: "Created",
      dataIndex: "createdAt",
      render: (record) => {
        return (
          <span>
            {record
              ? moment
                  .utc(record)
                  .local()
                  .format("DD MMM, YYYY @ hh:mm:ss")
              : undefined}
          </span>
        );
      },
    },

    {
      key: "actions",
      title: "Actions",
      render: (record) => {
        return (
          <>
            <EditOutlined
              onClick={() => {
                onEditRecord(record);
              }}
            />
            <DeleteOutlined
              onClick={() => {
                onDeleteRecord(record);
              }}
              style={{ color: "red", marginLeft: 12 }}
            />
          </>
        );
      },
    },
  ];

  async function requestAccount() {
    await window.ethereum.request({ method: "eth_requestAccounts" });
  }

  const { data = [], isLoading, isFetching, isError } = useGetUsersQuery(
    userGroup.find((group) => group.name === "Org Admin")
      ? { orgId: userOrgId }
      : {}
  );
  const { data: allUserDetails } = useGetUsersQuery();
  const [updateUser, { isUpdateLoading }] = useUpdateUserMutation();
  const [deleteUser, { isDeleteLoading }] = useDeleteUserMutation();

  if (!isLoading) {
  }

  const key = "updatable";
  const onEditRecord = (record) => {
    setEditingRecord({ ...record });
    setIsEditing(true);
  };

  const resetEditing = () => {
    setEditingRecord(null);
    setIsEditing(false);
  };

  const resetLinkUserForm = () => {
    setShowLinkUserModal(false);
  };

  const handleEditRecord = async (record) => {
    try {
      await updateUser(record)
        .unwrap()
        .then((res) => {
          message.success({
            content: "User updated successfully!",
            key,
            duration: 2,
          });
        })
        .catch((err) => {
          message.error({
            content: "Failed to update user!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch {}
  };

  const handleDeleteRecord = async (id) => {
    try {
      await deleteUser(id)
        .unwrap()
        .then((res) => {
          message.success({
            content: "Deleted user successfully!",
            key,
            duration: 2,
          });
        })
        .catch((err) => {
          message.error({
            content: "Failed to delete user!",
            key,
            duration: 2,
          });
          console.log(err);
        });
    } catch {}
  };

  const onCreate = async (values) => {
    try {
      let shouldContinue = true;
      const oldGroups = editingRecord.group;
      const newGroups = values.group;
      // Find removed items
      const removedItems = oldGroups.filter(
        (item) => !newGroups.includes(item)
      );
      // Find added items
      const addedItems = newGroups.filter((item) => !oldGroups.includes(item));
      console.log("Removed Items:", removedItems);
      console.log("Added Items:", addedItems);

      for (const groupkey of removedItems) {
        const foundGroup = allgroupList.find((item) => item._id === groupkey);

        const payload = {
          groupname: foundGroup?.name,
          userbaddress: values?.baddress?.toLowerCase(),
        };
        if (!userGroupName.includes("Org Admin")) {
          if (!(await revokeProbaRoleActionInBC(payload))) {
            shouldContinue = false;
          }
        }
      }

      for (const groupkey of addedItems) {
        const foundGroup = allgroupList.find((item) => item._id === groupkey);
        const payload = {
          groupname: foundGroup?.name,
          userbaddress: values?.baddress?.toLowerCase(),
        };
        if (!userGroupName.includes("Org Admin")) {
          if (!(await grantProbaActionInBC(payload))) {
            shouldContinue = false;
          }
        }
      }

      if (!shouldContinue) return;

      const updatedRecord = {
        _id: editingRecord._id,
        baddress: values?.baddress?.toLowerCase(),
        org: values.org,
        group: values.group,
      };
      
      setEditingRecord((prev) => ({ ...prev, ...updatedRecord }));
      handleEditRecord(updatedRecord);
      setIsEditing(false);
    } catch {
    } finally {
    }
  };

  const onDeleteRecord = (record) => {
    Modal.confirm({
      title: "Are you sure, you want to delete this  record?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        handleDeleteRecord({ ...record }._id);
      },
    });
  };

  const onUserFormUpdate = async (values) => {
    const orgAdminId = userGroup.find((group) => group.name === "Org Admin")
      ? userOrgId
      : {};

    // Extract userEmail from values
    const { userEmail } = values;

    // Find the matching users in allUserDetails
    const userIndex = allUserDetails?.userList?.findIndex(
      (user) => user.email === userEmail
    );

    if (userIndex !== -1) {
      const updatedUser = {
        ...allUserDetails?.userList[userIndex],
        org: orgAdminId,
        baddress: userBcAddress,
      };
      await handleEditRecord(updatedUser);
      setShowLinkUserModal();
    } else {
      console.log("User not found");
    }
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography.Title level={2}>Users</Typography.Title>
        <div style={{ display: "flex", alignItems: "center" }}>
          {userGroupName.includes("Org Admin") && (
            <Button
              type="primary"
              onClick={() => {
                setShowLinkUserModal(true);
              }}
            >
              Add User
            </Button>
          )}
        </div>
      </div>
      <div>
        <Card className="shadow-card" style={{ borderRadius: 8 }}>
          <Table
            size="small"
            columns={columns}
            dataSource={data.userList}
          ></Table>
        </Card>
      </div>
      <>
        {isEditing && (
          <CollectionCreateForm
            visible={isEditing}
            onCreate={onCreate}
            onCancel={() => {
              resetEditing();
            }}
            editingRecord={editingRecord}
            isAdmin={isAdmin}
          />
        )}
      </>
      <>
        {showLinkUserModal && (
          <LinkUserFormModal
            visible={showLinkUserModal}
            onUserFormUpdate={onUserFormUpdate}
            onCancel={() => {
              resetLinkUserForm();
            }}
            userDetails={allUserDetails}
            userOrgName={userOrgName}
          />
        )}
      </>
    </div>
  );
}

const CollectionCreateForm = ({
  visible,
  onCreate,
  onCancel,
  editingRecord,
  isAdmin,
}) => {
  const { Option } = Select;
  const [spinning, setSpinning] = useState(false);

  const [form] = Form.useForm();

  const { data: orgdata, isLoading: isorgLoading } = useGetOrgsQuery();
  const { data: groupdata, isLoading: isgroupLoading } = useGetGroupsQuery();

  const userGroups = useSelector((state) => state.auth?.groups);

  const orgDataOptions = [];
  if (!isorgLoading && orgdata && orgdata.orgList) {
    console.log("org data", orgdata);
    orgdata.orgList?.forEach((org) => {
      orgDataOptions.push(<Option key={org._id}>{org.name}</Option>);
    });
  }

  const groupDataOptions = [];
  if (!isgroupLoading && groupdata && editingRecord?.group) {
    const groupIds = editingRecord?.group;
    var isOrgAdminFlag = groupdata?.some(
      (group) => groupIds.includes(group._id) && group.name === "Org Admin"
    );
    if (isAdmin) {
      groupdata.forEach((group) => {
        groupDataOptions.push(
          <Option key={group._id} value={group._id} label={group.name}>
            {group.name}
          </Option>
        );
      });
    } else {
      userGroups
        ?.filter((group) => group.name !== "Org Admin" || isOrgAdminFlag)
        .forEach((group) => {
          groupDataOptions.push(
            <Option key={group._id} value={group._id} label={group.name}>
              {group.name}
            </Option>
          );
        });
    }
  }

  return (
    <Modal
      width={1000}
      title="Edit User"
      open={visible}
      okText="Save"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then(async (values) => {
            setSpinning(true);
            form.resetFields();
            await onCreate(values);

            setSpinning(false);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Spin spinning={spinning}>
        <Form
          {...formItemLayout}
          form={form}
          name="user"
          initialValues={editingRecord}
        >
          <Form.Item
            name="fname"
            label="First Name"
            rules={[
              {
                required: true,
                message: "Please input First name!",
              },
            ]}
          >
            <Input readOnly disabled />
          </Form.Item>
          <Form.Item
            name="lname"
            label="Last Name"
            rules={[
              {
                required: true,
                message: "Please input Last name!",
              },
            ]}
          >
            <Input readOnly disabled />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input readOnly disabled />
          </Form.Item>
          <Form.Item
            name="org"
            label="Organization"
            rules={[
              {
                required: true,
                message: "Please provide your Organization details!",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              disabled={!isAdmin}
              style={{
                width: "100%",
              }}
              placeholder="Please select the Organization"
            >
              {orgDataOptions}
            </Select>
          </Form.Item>
          <Form.Item name="group" label="Group" rules={[]}>
            <Select
              showSearch
              allowClear
              disabled={isOrgAdminFlag && !isAdmin}
              style={{
                width: "100%",
              }}
              placeholder="Please select the Group"
              mode="multiple"
              optionLabelProp="label"
            >
              {groupDataOptions}
            </Select>
          </Form.Item>
          <Form.Item
            name="baddress"
            label="Blockchain Address"
            disabled={!isAdmin}
            rules={[]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

const LinkUserFormModal = ({
  visible,
  onCancel,
  onUserFormUpdate,
  userDetails,
  userOrgName,
}) => {
  const filteredUsers = userDetails
    ? userDetails?.userList?.filter((user) => !user.org || user.org === "")
    : [];

  const [form] = Form.useForm();
  const [emailMatchStatus, setEmailMatchStatus] = useState(null);

  return (
    <Modal
      width={500}
      title="Add User"
      open={visible}
      okText="Add User"
      onCancel={() => {
        form.resetFields();
        setEmailMatchStatus(null);
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            setEmailMatchStatus(null);
            onUserFormUpdate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form layout="vertical" form={form} name="addUser">
        <p>
          Enter the email address of the user you want to add to the following
          organization: <b>{userOrgName}</b>.
        </p>
        <Form.Item
          name="userEmail"
          label="User Email"
          rules={[
            {
              required: true,
              message: "Please enter the User Email to link",
            },
            {
              type: "email",
              message: "Please enter a valid email",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const user = filteredUsers?.find(
                  (user) => user?.email === value
                );
                if (user) {
                  setEmailMatchStatus("success");
                  return Promise.resolve();
                } else {
                  setEmailMatchStatus("error");
                  return Promise.reject(new Error("Email does not match!"));
                }
              },
            }),
          ]}
          validateStatus={emailMatchStatus}
          help={
            emailMatchStatus === "success" ? (
              <span style={{ color: "green" }}>Email match found</span>
            ) : null
          }
          extra={
            <p>The email address must match an existing user in the system.</p>
          }
        >
          <Input
            onChange={() => setEmailMatchStatus(null)}
            suffix={
              emailMatchStatus === "success" ? (
                <CheckCircleOutlined
                  style={{ color: "green", fontSize: "16px" }}
                />
              ) : null
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default User;

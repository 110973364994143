import { useSigner, useAccount, useContract } from "wagmi";
import { writeContract, prepareWriteContract, readContract } from "@wagmi/core";
import {
  createGHGProjectAction,
  createAssetsAction,
  createYieldsAction,
  validateGHGProjectAction,
  validateAssetsAction,
  validateYieldsAction,
  verifyYieldsAction,
  createEntitlementRecordsAction,
  createTradableCertificateAction,
  createAllocateAction,
  createClaimAction,
  createCancelPreCreditsAction,
  acceptCancelOfPreCreditsAction,
  revokeProbaRoleAction,
  grantProbaRoleAction,
} from "./../constants/actionNames";
import { HashZero, MaxUint256 } from "@ethersproject/constants";
import {
  YOUR_ETH_NODE_ADDRESS,
  YOUR_PROBA_CONTRACT_ADDRESS,
  YOUR_PROBA_CONTRACT_ADDRESS_ABI,
} from "./../config/config";

import { id } from "@ethersproject/hash";
import { BigNumber, ethers, utils } from "ethers";

import { message } from "antd";
import { generateMessageFromStatus } from "./MessageUtils";
import {
  useUpdateAssetBatchMutation,
  useUpdateAssetMutation,
} from "../store/is/assetapi";
import { useUpdateInterventionMutation } from "../store/is/interventionapi";

import {
  useUpdateYieldBatchMutation,
  useUpdateYieldMutation,
} from "../store/is/yieldapi";

import { use } from "chai";
import { useSelector, useDispatch } from "react-redux";

import {
  finaltokApi,
  useAddFinalTokenMutation,
  useUpdateFinalTokenRecordsMutation,
} from "../store/is/finaltokapi";
import store from "../store/store";

import { React, useState } from "react";
import { getUser, getUserByOrg } from "../auth/authutils";
import { DisplayDateTime } from "../Components/Utils/DisplayDateTime";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import { ifaceProba } from "./BCUtil";
import { postFinalCertificateAttributesToIpfs } from "../ipfs/storenfra";
import {
  useAddPreEntitlementRecordMutation,
  useUpdatePreEntitlementRecordsMutation,
  useLazyGetPreEntitlementRecordByParamQuery,
} from "../store/is/preentitlementrecordsapi";
import { useSendBlockChainRequestMutation } from "../store/bc-client/bcclientapi";
import {
  useAddEntitlementCreditBundleMutation,
  useUpdateEntitlementCreditBundlesMutation,
} from "../store/is/entitlementCreditBundleApi";

export const useTransactionHandlers = (transaction) => {
  const { createGHGProjectSubmit } = useCreateGHGProjectHandlers({
    transaction,
  });
  const { createAssetsSubmit } = useCreateAssetsHandlers({ transaction });
  const { createYieldsSubmit } = useCreateYieldsHandlers({ transaction });
  const { validateGHGProjectSubmit } = useValidateGHGProjectHandlers({
    transaction,
  });
  const { validateAssetsSubmit } = useValidateAssetsHandlers({ transaction });
  const { validateYieldsSubmit } = useValidateYieldsHandlers({ transaction });
  const { verifyYieldsSubmit } = useVerifyYieldsHandlers({ transaction });
  const {
    createEntitlementRecordsSubmit,
  } = useCreateEntitlementRecordsHandlers({ transaction });
  const {
    createTradableCertificateSubmit,
  } = useCreateTradableCertificateHandlers({ transaction });
  const { createAllocateSubmit } = useCreateAllocateHandlers({ transaction });
  const { createClaimSubmit } = useCreateClaimHandlers({ transaction });
  const {
    createCancelPreCreditsSubmit,
  } = useCreateCancelPreCreditsSubmitHandlers({ transaction });
  const {
    acceptCancelOfPreCreditsSubmit,
  } = useAcceptCancelOfPreCreditsSubmitHandlers({ transaction });

  const { grantProbaRoleSubmit } = useGrantProbaRoleHandlers({ transaction });
  const { revokeProbaRoleSubmit } = useRevokeProbaRoleHandlers({ transaction });

  let usergroup = useSelector((state) =>
    state.auth.groups?.map((group) => group?.name)
  );
  usergroup = usergroup?.filter(Boolean);

  const handlers = new Map([
    [
      createGHGProjectAction.name,
      {
        onAccept: createGHGProjectSubmit,
      },
    ],

    [
      createAssetsAction.name,
      {
        onAccept: createAssetsSubmit,
      },
    ],

    [
      createYieldsAction.name,
      {
        onAccept: createYieldsSubmit,
      },
    ],

    [
      validateGHGProjectAction.name,
      {
        onAccept: validateGHGProjectSubmit,
      },
    ],

    [
      validateAssetsAction.name,
      {
        onAccept: validateAssetsSubmit,
      },
    ],
    [
      verifyYieldsAction.name,
      {
        onAccept: verifyYieldsSubmit,
      },
    ],

    [
      validateYieldsAction.name,
      {
        onAccept: validateYieldsSubmit,
      },
    ],

    [
      createEntitlementRecordsAction.name,
      {
        onAccept: createEntitlementRecordsSubmit,
      },
    ],

    [
      createTradableCertificateAction.name,
      {
        onAccept: createTradableCertificateSubmit,
      },
    ],

    [
      createAllocateAction.name,
      {
        onAccept: createAllocateSubmit,
      },
    ],

    [
      createClaimAction.name,
      {
        onAccept: createClaimSubmit,
      },
    ],

    [
      createCancelPreCreditsAction.name,
      {
        onAccept: createCancelPreCreditsSubmit,
      },
    ],

    [
      acceptCancelOfPreCreditsAction.name,
      {
        onAccept: acceptCancelOfPreCreditsSubmit,
      },
    ],

    [
      grantProbaRoleAction.name,
      {
        onAccept: grantProbaRoleSubmit,
      },
    ],

    [
      revokeProbaRoleAction.name,
      {
        onAccept: revokeProbaRoleSubmit,
      },
    ],
  ]);
  return handlers.has(transaction?.actionname)
    ? {
        onAccept: async (payload) => {
          return await handlers.get(transaction?.actionname).onAccept(payload);
        },
      }
    : {
        onAccept: () => {
          console.error(
            `transaction accept handler does not exist - ${transaction?.actionname}`
          );
        },
      };
};

export const displayErrorMessage = (error) => {
  const errorMessage =
    error?.error?.reason ??
    error?.reason ??
    error?.message ??
    error?.data ??
    "Something went wrong";

  message.error({
    content: errorMessage,
    key: "updatable",
  });
};

const useGrantProbaRoleHandlers = ({ transaction }) => {
  const [sendBlockChainRequest] = useSendBlockChainRequestMutation();
  const grantProbaRoleSubmit = async (payload) => {
    const groupname = payload?.groupname;
    const userbaddress = payload?.userbaddress;
    const bcRoleName = groupNameBcMappings[groupname] || "DEFAULT_GROUP";
    if (bcRoleName === "DEFAULT_GROUP") {
      //do nothing..
      return true;
    }

    // converting string to  bytes32
    const bcRoleNameConverted = id(bcRoleName);

    try {
      const bcPayload = {
        contractAddress: YOUR_PROBA_CONTRACT_ADDRESS,
        functionName: grantProbaRoleAction.name,
        functionArguments: [userbaddress, bcRoleNameConverted],
      };

      const txResponse = await sendBlockChainRequest(bcPayload).unwrap();
      generateMessageFromStatus({
        status: "success",
        message: "Access Granting submitted blockchain for processing",
      });
      return true;
    } catch (error) {
      console.error(error);
      displayErrorMessage(error);
      return false;
    }
  };

  return { grantProbaRoleSubmit };
};

const groupNameBcMappings = {
  "Project Sponsor": "PROJECT_SPONSOR",
  "Project Developer": "PROJECT_DEVELOPER",
  Validator: "VALIDATOR",
  Verifier: "VERIFIER",
  "Proba Operator": "PROBA_OPERATOR",
};

const useRevokeProbaRoleHandlers = ({ transaction }) => {
  const [sendBlockChainRequest] = useSendBlockChainRequestMutation();
  const revokeProbaRoleSubmit = async (payload) => {
    const groupname = payload?.groupname;
    const userbaddress = payload?.userbaddress;
    const bcRoleName = groupNameBcMappings[groupname] || "DEFAULT_GROUP";
    if (bcRoleName === "DEFAULT_GROUP") {
      //do nothing
      return true;
    }

    // converting string to  bytes32
    const bcRoleNameConverted = id(bcRoleName);
    try {
      const bcPayload = {
        contractAddress: YOUR_PROBA_CONTRACT_ADDRESS,
        functionName: revokeProbaRoleAction.name,
        functionArguments: [userbaddress, bcRoleNameConverted],
      };

      const txResponse = await sendBlockChainRequest(bcPayload).unwrap();
      generateMessageFromStatus({
        status: "success",
        message: "Access Revoking submitted blockchain for processing",
      });
      return true;
    } catch (error) {
      console.error(error);
      displayErrorMessage(error);
      return false;
    }
  };

  return { revokeProbaRoleSubmit };
};

const useCreateGHGProjectHandlers = ({ transaction }) => {
  const [sendBlockChainRequest] = useSendBlockChainRequestMutation();
  const [updateIntervention] = useUpdateInterventionMutation();

  const createGHGProjectSubmit = async (payload) => {
    const intervention = payload?.intervention;
    const interventionIdsByte32 = id(intervention._id);
    try {
      const bcPayload = {
        contractAddress: YOUR_PROBA_CONTRACT_ADDRESS,
        functionName: createGHGProjectAction.name,
        functionArguments: [interventionIdsByte32],
      };

      const txResponse = await sendBlockChainRequest(bcPayload).unwrap();
      generateMessageFromStatus({
        status: "success",
        message:
          "Successfully submitted GHG Project to the blockchain for processing!",
      });
      const payload = {
        id: intervention?._id,
        body: {
          ...intervention,
          is_updated_to_blockchain: true,
          external_intervention_id: intervention?.external_intervention_id,
        },
      };
      // Update the intervention with the blockchain timestamp
      await updateIntervention(payload)
        .unwrap()
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });
      return true;
    } catch (error) {
      console.error(error);
      displayErrorMessage(error);
      return false;
    }
  };

  return { createGHGProjectSubmit };
};

export const useCreateAssetsHandlers = ({ transaction }) => {
  const [sendBlockChainRequest] = useSendBlockChainRequestMutation();
  const [updateAsset] = useUpdateAssetMutation();
  const [updateAssetBatch] = useUpdateAssetBatchMutation();

  const createAssetsSubmit = async (payload) => {
    const assets = payload?.assets;
    const intervention = payload?.intervention;
    if (assets && assets.length === 0) return false;

    let assetIdsByte32 = [];
    for (var i = 0; i < assets.length; i++) {
      assetIdsByte32.push(id(assets[i]?._id));
    }

    try {
      const bcPayload = {
        contractAddress: YOUR_PROBA_CONTRACT_ADDRESS,
        functionName: createAssetsAction.name,
        functionArguments: [id(intervention?._id), assetIdsByte32],
      };

      const txResponse = await sendBlockChainRequest(bcPayload).unwrap();
      generateMessageFromStatus({
        status: "success",
        message:
          "Asset successfully submitted to the blockchain for processing",
      });

      const batchSize = 100;
      for (let i = 0; i < assets.length; i += batchSize) {
        const batchAssets = assets.slice(i, i + batchSize);
        const assetsToUpdateBatch = [];
        batchAssets.forEach((asset) => {
          const assetToUpdate = { ...asset };
          assetToUpdate.is_updated_to_blockchain = true;
          assetsToUpdateBatch.push(assetToUpdate);
        });
        // Update the asset with the blockchain timestamp
        await updateAssetBatch(assetsToUpdateBatch);
      }

      return true;
    } catch (error) {
      console.error(error);
      displayErrorMessage(error);
      return false;
    }
  };

  return { createAssetsSubmit };
};
export const useCreateYieldsHandlers = ({ transaction }) => {
  const [sendBlockChainRequest] = useSendBlockChainRequestMutation();
  const [updateYield] = useUpdateYieldMutation();
  const [updateYieldBatch] = useUpdateYieldBatchMutation();

  const createYieldsSubmit = async (payload) => {
    const yields = payload?.yields;
    let assetIdsByte32 = [];
    let yieldIdsByte32 = [];
    for (var i = 0; i < yields?.length; i++) {
      assetIdsByte32.push(id(yields[i]?.asset_id));
      yieldIdsByte32.push(id(yields[i]?._id));
    }
    try {
      const bcPayload = {
        contractAddress: YOUR_PROBA_CONTRACT_ADDRESS,
        functionName: createYieldsAction.name,
        functionArguments: [assetIdsByte32, yieldIdsByte32],
        waitOnReceipt: true,
      };

      const txResponse = await sendBlockChainRequest(bcPayload).unwrap();

      const { logs } = txResponse;

      const yieldsToUpdate = [];
      for (var i = 0; i < logs.length; i++) {
        //1. filter the YieldsCreated event
        const eventHashString = ifaceProba.getEventTopic("YieldsCreated");
        if (eventHashString === logs[i].topics[0]) {
          let tokenId = BigNumber.from(logs[i].topics[1]).toString();
          let yieldId = logs[i].topics[3];
          //2. Yield Records to Update
          const yieldSlected = yields.find(
            (yieldin) => id(yieldin?._id) === yieldId
          );
          let yieldToUpdate = { ...yieldSlected };
          yieldToUpdate.token_id = tokenId;
          yieldToUpdate.token_name = "NFRY";
          yieldToUpdate.is_updated_to_blockchain = true;
          yieldsToUpdate.push(yieldToUpdate);
        }
      }
      const batchSize = 100;
      for (let i = 0; i < yieldsToUpdate.length; i += batchSize) {
        const batchYields = yieldsToUpdate.slice(i, i + batchSize);
        await updateYieldBatch(batchYields);
      }

      generateMessageFromStatus({
        status: "success",
        message:
          "Yield successfully submitted to the blockchain for processing",
      });
      return true;
    } catch (error) {
      console.error(error);
      displayErrorMessage(error);
      return false;
    }
  };

  return { createYieldsSubmit };
};

export const useValidateGHGProjectHandlers = ({ transaction }) => {
  const [sendBlockChainRequest] = useSendBlockChainRequestMutation();

  const validateGHGProjectSubmit = async (payload) => {
    const dochashesByte32 = (payload?.dochashes || []).map((hash) => id(hash));

    try {
      const bcPayload = {
        contractAddress: YOUR_PROBA_CONTRACT_ADDRESS,
        functionName: validateGHGProjectAction.name,
        functionArguments: [id(payload?.intervention?._id), dochashesByte32],
      };

      const txResponse = await sendBlockChainRequest(bcPayload).unwrap();
      generateMessageFromStatus({
        status: "success",
        message:
          "Successfully submitted the GHG project validation to the blockchain for processing",
      });
      return true;
    } catch (error) {
      console.error(error);
      displayErrorMessage(error);
      return false;
    }
  };

  return { validateGHGProjectSubmit };
};

export const useValidateAssetsHandlers = ({ transaction }) => {
  const [sendBlockChainRequest] = useSendBlockChainRequestMutation();

  const validateAssetsSubmit = async (payload) => {
    const assetIds = payload?.assets || [];
    const dochashes = payload?.dochashes || [];

    const assetIdsByte32 = [];
    for (var i = 0; i < assetIds?.length; i++) {
      assetIdsByte32.push(id(assetIds[i]?._id));
    }

    // Convert documentHashes to bytes32
    const dochashesByte32 = dochashes.map((hash) => id(hash));

    try {
      const bcPayload = {
        contractAddress: YOUR_PROBA_CONTRACT_ADDRESS,
        functionName: validateAssetsAction.name,
        functionArguments: [assetIdsByte32, dochashesByte32],
      };

      const txResponse = await sendBlockChainRequest(bcPayload).unwrap();
      generateMessageFromStatus({
        status: "success",
        message:
          "Successfully submitted the Asset validation to the blockchain for processing",
      });
      return true;
    } catch (error) {
      console.error(error);
      displayErrorMessage(error);
      return false;
    }
  };
  return { validateAssetsSubmit };
};

export const useValidateYieldsHandlers = ({ transaction }) => {
  const [sendBlockChainRequest] = useSendBlockChainRequestMutation();

  const validateYieldsSubmit = async (payload) => {
    const nfryids = (payload?.yields || []).map(
      (yieldItem) => yieldItem?.token_id
    );
    // const dochashesByte32 = (payload?.dochashes || []).map(hash => id(hash));

    const dochashes = payload?.dochashes || [];
    const dochashesByte32 = dochashes.map((hash) => id(hash));

    try {
      const bcPayload = {
        contractAddress: YOUR_PROBA_CONTRACT_ADDRESS,
        functionName: validateYieldsAction.name,
        functionArguments: [nfryids, dochashesByte32],
      };

      const txResponse = await sendBlockChainRequest(bcPayload).unwrap();
      generateMessageFromStatus({
        status: "success",
        message:
          "Successfully submitted the Yields validation to the blockchain for processing",
      });
      return true;
    } catch (error) {
      console.error(error);
      displayErrorMessage(error);
      return false;
    }
  };

  return { validateYieldsSubmit };
};

export const useVerifyYieldsHandlers = ({ transaction }) => {
  const [sendBlockChainRequest] = useSendBlockChainRequestMutation();

  const verifyYieldsSubmit = async (payload) => {
    const nfryids = (payload?.yields || []).map(
      (yieldItem) => yieldItem?.token_id
    );
    const dochashesByte32 = (payload?.dochashes || []).map((hash) => id(hash));
    try {
      const bcPayload = {
        contractAddress: YOUR_PROBA_CONTRACT_ADDRESS,
        functionName: verifyYieldsAction.name,
        functionArguments: [nfryids, dochashesByte32],
      };

      const txResponse = await sendBlockChainRequest(bcPayload).unwrap();
      generateMessageFromStatus({
        status: "success",
        message:
          "Successfully submitted the Yield  Verification to the blockchain for processing",
      });
      return true;
    } catch (error) {
      console.error(error);
      displayErrorMessage(error);
      return false;
    }
  };

  return { verifyYieldsSubmit };
};

export const useCreateEntitlementRecordsHandlers = ({ transaction }) => {
  const [sendBlockChainRequest] = useSendBlockChainRequestMutation();
  const [updateYieldBatch] = useUpdateYieldBatchMutation();
  const [addPreEntitlementRecord] = useAddPreEntitlementRecordMutation();
  const [
    updatePreEntitlementRecords,
  ] = useUpdatePreEntitlementRecordsMutation();

  const userid = useSelector((state) => state.auth.userid);
  const orgId = useSelector((state) => state.auth?.org?._id);

  const createEntitlementRecordsSubmit = async (payload) => {
    const flag = payload?.flag;

    let bcsponsorAddress = "0x0000000000000000000000000000000000000000";
    const bcsponsorAddressfromOrgId = await getUserByOrg(payload?.sponsorOrgId);
    const developerAmount = [];
    const sponsorAmount = [];
    const nfryIds = [];
    const statusType = [];
    const yieldAmountsMap = [];
    const sponsorPreCreditsAvailability =
      payload?.sponsorPreCreditsAvailability;
    if (
      bcsponsorAddressfromOrgId &&
      bcsponsorAddressfromOrgId?.baddress &&
      sponsorPreCreditsAvailability
    ) {
      bcsponsorAddress = bcsponsorAddressfromOrgId?.baddress;
    }

    //PreCredits PrecreditPercentage

    let statusTypes;
    for (var i = 0; i < payload?.yields?.length; i++) {
      const yieldData = payload?.yields[i];
      const precreditsPercentage = payload?.precreditPercentage;

      let sponsorPercentage = 0;
      let devPercentage = 0;

      if (
        ["Pending Realization", "Proba Review", "Verifier Review"].includes(
          yieldData.status
        )
      ) {
        if (
          sponsorPreCreditsAvailability === true &&
          precreditsPercentage !== 0
        ) {
          sponsorPercentage =
            yieldData.co2_estimated * (precreditsPercentage / 100);
          devPercentage = yieldData.co2_estimated - sponsorPercentage;
        } else if (precreditsPercentage !== 0) {
          devPercentage =
            yieldData.co2_estimated * (precreditsPercentage / 100);
        }
      } else if (yieldData.status === "Realized") {
        if (
          sponsorPreCreditsAvailability === true &&
          precreditsPercentage !== 0
        ) {
          sponsorPercentage =
            yieldData.co2_estimated * (precreditsPercentage / 100);
          devPercentage = yieldData.co2_realized - sponsorPercentage;
        } else {
          devPercentage = yieldData.co2_realized;
        }
      } else {
        throw new Error("Invalid status: " + yieldData.status);
      }
      developerAmount.push(devPercentage);
      sponsorAmount.push(sponsorPercentage);
      nfryIds.push(yieldData?.token_id);
      // Map the yield ID to its amounts

      if (
        sponsorPreCreditsAvailability === true &&
        precreditsPercentage !== 0
      ) {
        yieldAmountsMap.push(
          {
            yield_id: yieldData?._id,
            percentage: devPercentage,
            co2_realized: yieldData?.co2_realized,
            token_id: yieldData?.token_id,
          },
          {
            yield_id: yieldData?._id,
            percentage: sponsorPercentage,
            co2_realized: yieldData?.co2_realized,
            sponsor_organization_id: payload?.sponsorOrgId,
            token_id: yieldData?.token_id,
          }
        );
      } else {
        yieldAmountsMap.push({
          yield_id: yieldData?._id,
          percentage: devPercentage,
          co2_realized: yieldData?.co2_realized,
          token_id: yieldData?.token_id,
        });
      }

      if (yieldData.status === "Pending Realization") {
        statusTypes = "Pre-credits";
      } else if (yieldData.status === "Realized") {
        statusTypes = "Credits";
      } else {
        throw new Error("Invalid status: " + yieldData.status);
      }
      statusType.push(statusTypes);
    }
    const developerRecords = developerAmount.map((amount) =>
      Math.round(amount * 10000)
    );
    const sponsorRecords = sponsorAmount.map((amount) =>
      Math.round(amount * 10000)
    );

    try {
      const bcPayload = {
        contractAddress: YOUR_PROBA_CONTRACT_ADDRESS,
        functionName: createEntitlementRecordsAction.name,
        functionArguments: [
          bcsponsorAddress,
          developerRecords,
          sponsorRecords,
          nfryIds,
          statusType,
        ],
        waitOnReceipt: true,
      };
      const txResponse = await sendBlockChainRequest(bcPayload).unwrap();
      const { logs } = txResponse;
      const yieldsToUpdate = [];
      const entitlementRecordListToUpdate = [];
      const entitlementRecordListToAdd = [];

      const yieldMap = new Map();
      const entitlementRecords = payload?.entitlementRecordDetails;
      if (entitlementRecords) {
        for (let i = 0; i < entitlementRecords.length; i++) {
          const entitlementRecord = entitlementRecords[i];
          const mapValue = yieldMap.get(entitlementRecord.yield_id);
          const newValue = mapValue
            ? [...mapValue, entitlementRecord]
            : [entitlementRecord];
          yieldMap.set(entitlementRecord.yield_id, newValue);
        }
      }

      // Create a set to store unique yield_ids
      const processedYieldIds = new Set();

      for (let i = 0; i < yieldAmountsMap.length; i++) {
        const yieldAmountMap = yieldAmountsMap[i];
        // Skip if this yield_id has already been processed
        if (processedYieldIds.has(yieldAmountMap.yield_id) && yieldAmountMap?.co2_realized) {
          continue; // Move to the next iteration
        }

        // Add the yield_id to the set
        processedYieldIds.add(yieldAmountMap.yield_id);
        const entitlements = yieldMap.get(yieldAmountMap.yield_id);
        if (entitlements?.length > 0) {
          const sumOfEntitlements = entitlements?.reduce(
            (total, entitlement) => {
              if (entitlement.organization_id !== orgId) {
                return total + entitlement.no_of_entitlement_record;
              } else if (
                entitlement.organization_id === orgId &&
                entitlement?.from_org === orgId &&
                entitlement?.to_org === orgId
              ) {
                return total + entitlement.no_of_entitlement_record;
              }
              return total;
            },
            0
          );
          const hasDev = entitlements.some(
            (entitlementRecord) => entitlementRecord.organization_id === orgId
          );
          if (!hasDev && (yieldAmountMap?.co2_realized ?? false)) {
            const noOfEntitlementRecord = yieldAmountMap?.co2_realized - sumOfEntitlements;
          
            if (noOfEntitlementRecord !== 0) {
              const devEntitlementRecord = {
                yield_id: yieldAmountMap?.yield_id,
                organization_id: orgId,
                no_of_entitlement_record: noOfEntitlementRecord,
                final_tradable_cert_uuid: "",
                status:
                  statusTypes === "Pre-credits"
                    ? "notrealized"
                    : statusTypes === "Credits"
                    ? "realized"
                    : "",
                is_updated_to_blockchain: true,
              };
              entitlementRecordListToAdd.push(devEntitlementRecord);
            }
          }
          for (let j = 0; j < entitlements.length; j++) {
            const entitlementRecordToUpdate = entitlements[j];
            let no_of_entitlement_record =
              entitlementRecordToUpdate?.no_of_entitlement_record;
            const status =
              statusTypes === "Pre-credits"
                ? "notrealized"
                : statusTypes === "Credits"
                ? "realized"
                : "";
            const fromOrg = entitlementRecordToUpdate?.from_org;
            const toOrg = entitlementRecordToUpdate?.to_org;
            if (
              entitlementRecordToUpdate?.organization_id === orgId &&
              fromOrg === "" &&
              toOrg === ""
            ) {
              no_of_entitlement_record =
                yieldAmountMap?.co2_realized - sumOfEntitlements;
            }
            entitlementRecordListToUpdate.push({
              ...entitlementRecordToUpdate,
              no_of_entitlement_record,
              status,
            });
          }
        } else {
          const entitlementRecordToAdd = { ...yieldAmountMap };
          entitlementRecordToAdd.yield_id = yieldAmountMap.yield_id;
          entitlementRecordToAdd.no_of_entitlement_record =
            yieldAmountMap.percentage;
          if (yieldAmountMap?.sponsor_organization_id) {
            entitlementRecordToAdd.organization_id = yieldAmountMap?.sponsor_organization_id;
          }
          else {
            entitlementRecordToAdd.organization_id = orgId;
          }
          entitlementRecordToAdd.final_tradable_cert_uuid = "";
          entitlementRecordToAdd.status =
            statusTypes === "Pre-credits"
              ? "notrealized"
              : statusTypes === "Credits"
              ? "realized"
              : "";
          entitlementRecordToAdd.is_updated_to_blockchain = true;
          entitlementRecordListToAdd.push(entitlementRecordToAdd);

          for (let k = 0; k < payload?.yields?.length; k++) {
            const yieldRecord = payload.yields[k];
            if (yieldRecord._id === yieldAmountMap.yield_id) {
              yieldRecord.is_pre_credits_available = true;
              yieldsToUpdate.push(yieldRecord);
            }
          }
        }
      }

      const batchSize = 100;

      for (let i = 0; i < yieldsToUpdate.length; i += batchSize) {
        const batchYields = yieldsToUpdate.slice(i, i + batchSize);
        await updateYieldBatch(batchYields);
      }

      if (flag === "preentitlement") {
        for (
          let i = 0;
          i < entitlementRecordListToAdd?.length;
          i += batchSize
        ) {
          const batchYields = entitlementRecordListToAdd.slice(
            i,
            i + batchSize
          );
          await addPreEntitlementRecord(batchYields);
        }
      } else if (flag === "entitlement") {
        for (
          let i = 0;
          i < entitlementRecordListToAdd?.length;
          i += batchSize
        ) {
          const batchYields = entitlementRecordListToAdd.slice(
            i,
            i + batchSize
          );
          await addPreEntitlementRecord(batchYields);
        }
        for (
          let i = 0;
          i < entitlementRecordListToUpdate?.length;
          i += batchSize
        ) {
          const batchYields = entitlementRecordListToUpdate.slice(
            i,
            i + batchSize
          );
          await updatePreEntitlementRecords(batchYields);
        }
      }

      generateMessageFromStatus({
        status: "success",
        message:
          "Successfully submitted the Entitlement Record creation to the blockchain for processing",
      });
      return true;
    } catch (error) {
      console.error(error);
      generateMessageFromStatus({
        status: "error",
        message: error,
      });
    }
  };

  return { createEntitlementRecordsSubmit };
};

export const useCreateTradableCertificateHandlers = ({ transaction }) => {
  const [sendBlockChainRequest] = useSendBlockChainRequestMutation();
  const [updateYield] = useUpdateYieldMutation();
  const [updateYieldBatch] = useUpdateYieldBatchMutation();
  const [
    updatePreEntitlementRecords,
  ] = useUpdatePreEntitlementRecordsMutation();
  const [
    getPreEntitlementRecords,
  ] = useLazyGetPreEntitlementRecordByParamQuery();
  const [addEntitlementCreditBundle] = useAddEntitlementCreditBundleMutation();

  const createTradableCertificateSubmit = async (payload) => {
    try {
      const final_certificate_name = "PTCB";
      const noOfEntitlementRecords = [];
      const nfryIds = [];
      const sumOfToekns = [];
      let updatedTokensSum = 0;

      noOfEntitlementRecords.push(payload.amounts);
      for (var i = 0; i < payload?.yields?.length; i++) {
        const yieldData = payload?.yields[i];
        const tokenIdUint256 = parseInt(
          payload?.yields[i]?.yield_details?.token_id
        );
        nfryIds.push(tokenIdUint256);

        let sumOfToekn =
          yieldData?.updated_no_of_entitlement_record ??
          yieldData?.no_of_entitlement_record; //TODO updated_no_of_entitlement_record
        sumOfToekns.push(Math.round(sumOfToekn * 10000));

        updatedTokensSum +=
          yieldData?.updated_no_of_entitlement_record ??
          yieldData?.no_of_entitlement_record;
      }
      noOfEntitlementRecords.push(payload?.amounts);

      const bcPayload = {
        contractAddress: YOUR_PROBA_CONTRACT_ADDRESS,
        functionName: createTradableCertificateAction.name,
        functionArguments: [
          id(final_certificate_name),
          sumOfToekns,
          nfryIds,
          id(payload?.metadata_uri),
        ],
        waitOnReceipt: true,
      };

      const txResponse = await sendBlockChainRequest(bcPayload).unwrap();
      const { logs } = txResponse;

      let finalTokenId = null;
      for (const log of logs) {
        const eventHashString = ifaceProba.getEventTopic(
          "TradableCertificateCreated"
        );
        if (eventHashString === log.topics[0]) {
          finalTokenId = BigNumber.from(log.topics[1]).toString();
          break;
        }
      }

      if (finalTokenId) {
        const record = {
          no_of_token: updatedTokensSum,
          finaltoken_id: finalTokenId,
          finaltoken_name: final_certificate_name,
          type: payload?.type,
          status: payload.status,
          metadata_uri: payload.metadata_uri,
          final_certificate_json: payload.finalCertificateJsonData,
        };

        const { data, isLoading } = await store.dispatch(
          finaltokApi.endpoints.addFinalToken.initiate([record])
        );

        if (!isLoading) {
          const entitlementsToUpdate = [];
          const batchPayload = [];

          for (const entitlementRecordData of payload.yields) {
            let entitlementToUpdate = { ...entitlementRecordData };
            if (
              entitlementToUpdate.updated_no_of_entitlement_record !==
                undefined &&
              entitlementToUpdate.updated_no_of_entitlement_record !== null
            ) {
              entitlementToUpdate.no_of_entitlement_record -=
                entitlementToUpdate.updated_no_of_entitlement_record;
              delete entitlementToUpdate.updated_no_of_entitlement_record;
            } else {
              entitlementToUpdate.status = "inactive";
            }
            entitlementToUpdate.final_tradable_cert_uuid = data?.data?.[0]?._id;
            entitlementsToUpdate.push(entitlementToUpdate);

            batchPayload.push({
              entitlement_record_uuid: entitlementRecordData._id,
              credit_bundle_uuid: data?.data?.[0]?._id,
              no_of_credits:
                entitlementRecordData.updated_no_of_entitlement_record ??
                entitlementRecordData.no_of_entitlement_record,
            });
          }

          // Send batch payload
          await addEntitlementCreditBundle(batchPayload);

          const batchSize = 100;
          for (let i = 0; i < entitlementsToUpdate.length; i += batchSize) {
            const batchEntitlements = entitlementsToUpdate.slice(
              i,
              i + batchSize
            );
            await updatePreEntitlementRecords(batchEntitlements);
          }
        }
      }

      generateMessageFromStatus({
        status: "success",
        message:
          "Successfully submitted the final Certificate creation to the blockchain for processing!",
      });

      return true;
    } catch (error) {
      console.error(error);
      displayErrorMessage(error);
      generateMessageFromStatus({
        status: "error",
        message: error.data ,
      });
      return false
    }
  };

  return { createTradableCertificateSubmit };
};

export const useCreateAllocateHandlers = ({ transaction }) => {
  const { address: account, isConnected } = useAccount();
  const userOwnWalletConnect = JSON.parse(localStorage.getItem("user"))?.useownwallet;
  const [sendBlockChainRequest] = useSendBlockChainRequestMutation();

  const [
    updatePreEntitlementRecords,
  ] = useUpdatePreEntitlementRecordsMutation();
  const [addPreEntitlementRecord] = useAddPreEntitlementRecordMutation();
  const [updateFinalTokenRecords] = useUpdateFinalTokenRecordsMutation();
  const [addFinalTokenRecord] = useAddFinalTokenMutation();
  const [
    updateEntitlementCreditBundles,
  ] = useUpdateEntitlementCreditBundlesMutation();
  const [addEntitlementCreditBundle] = useAddEntitlementCreditBundleMutation();
  const userBcAddress = useSelector((state) => state.auth.baddress);

  const getUpdatedYield = (
    finaltoken_name,
    finaltoken_id,
    assetName,
    yieldDetails
  ) => {
    return yieldDetails
      .filter(
        (detail) =>
          detail.finaltoken_name === finaltoken_name &&
          detail.finaltoken_id === finaltoken_id &&
          detail.asset_name === assetName &&
          (detail.updated_no_of_credits === undefined ||
            detail.updated_no_of_credits > 0)
      )
      ?.map((detail) => ({
        yield_co2_realized:
          detail.updated_no_of_credits !== undefined
            ? detail.updated_no_of_credits
            : detail.no_of_credits,
        yield_period: detail?.yield_period,
        yield_storage_duration: detail?.yield_storage_duration,
      }))?.filter((item)=>item.yield_co2_realized !==0)
  };

  const createAllocateSubmit = async (payload) => {
    const flag = payload?.flag;
    if (!isConnected && userOwnWalletConnect) {
      message.info({
        content: "Please Connect A Wallet",
        key: "updatable",
        duration: 2,
      });
      return false;
    }
    //get user details
    const toUser = await getUserByOrg(payload?.to);
    if (toUser?.error) {
      const errorMessage = toUser?.error?.response?.text;
      message.error({
        content: errorMessage,
        key: "updatable",
      });
      return false;
    }
    let tokenIds = [];
    let amounts = [];
    var tokenType = "";
    var _uri = "";

    if (flag === "EntitlementRecords") {
      const entitlementRecords = payload?.entitlementRecordsToTransfer;
      for (let i = 0; i < entitlementRecords?.length; i++) {
        const record = entitlementRecords[i];
        const tokenId = parseInt(record?.yield_details?.token_id);
        tokenType = "NFRY";
        _uri = "";
        const transferAmount =
          record?.no_of_entitlement_record -
          record?.updated_no_of_entitlement_record;
        tokenIds.push(tokenId);
        amounts.push(Math.round(transferAmount * 10000));
      }
    } else {
      const finalRecords = payload?.finalCertificate;
      const aggregatedFinalTokenData = {};

      for (let i = 0; i < payload?.finalCertificate.length; i++) {
        const item = payload?.finalCertificate[i];
        const parsedData = JSON.parse(item?.final_certificate_json).data[0];
        const { intervention_name, intervention_project_id } = parsedData;
        const key = `${intervention_name}_${intervention_project_id}`;

        if (aggregatedFinalTokenData[key]) {
          aggregatedFinalTokenData[key].amount_of_tradable_certificates =
            payload?.transferAmount;

          for (let j = 0; j < parsedData.assets.length; j++) {
            const asset = parsedData.assets[j];
            const existingAsset = aggregatedFinalTokenData[key].assets.find(
              (a) => a.asset_name === asset.asset_name
            );

            if (existingAsset) {
              // Get the updated yields from yieldDetails for the current asset
              const updatedYields = getUpdatedYield(
                item.finaltoken_name,
                item?.finaltoken_id,
                asset.asset_name,
                payload.yieldDetails
              );
              existingAsset.yields = [
                ...existingAsset.yields,
                ...updatedYields,
              ];
              aggregatedFinalTokenData[key].assets = aggregatedFinalTokenData[
                key
              ].assets.map((a) =>
                a.asset_name === existingAsset.asset_name
                  ? { ...a, yields: existingAsset.yields }
                  : a
              );
            } else {
              // If the asset doesn't exist, you can add it to the assets array if necessary
              aggregatedFinalTokenData[key].assets.push({
                ...asset,
                yields: getUpdatedYield(
                  item.finaltoken_name,
                  item?.finaltoken_id,
                  asset.asset_name,
                  payload.yieldDetails
                ), // Get yields for this new asset
              });
            }
          }
        } else {
          aggregatedFinalTokenData[key] = {
            ...parsedData,
            assets: parsedData.assets.map((asset) => ({
              ...asset,
              yields: getUpdatedYield(
                item.finaltoken_name,
                item?.finaltoken_id,
                asset.asset_name,
                payload.yieldDetails
              ),
            })),
            amount_of_tradable_certificates: payload?.transferAmount,
          };
        }
      }

      const aggregatedJson = { data: Object.values(aggregatedFinalTokenData) };
      var finalcertificateJsonPreviewData = JSON.stringify(aggregatedJson);

      var finalCertificateMetaDataUri = await postFinalCertificateAttributesToIpfs(
        aggregatedJson
      );

      for (let i = 0; i < finalRecords?.length; i++) {
        const record = finalRecords[i];
        const tokenId = parseInt(record?.finaltoken_id);
        const amount = record?.no_of_token - record?.updated_no_of_token;
        tokenType = record?.finaltoken_name;
        _uri = finalCertificateMetaDataUri;
        tokenIds.push(tokenId);
        amounts.push(Math.round(amount * 10000));
      }
    }
    try {
      let finalTokenId = null;
      if (userOwnWalletConnect) {
        const config = await prepareWriteContract({
          address: YOUR_PROBA_CONTRACT_ADDRESS,
          abi: YOUR_PROBA_CONTRACT_ADDRESS_ABI,
          functionName: createAllocateAction.name,
          args: [
            toUser?.baddress,
            tokenIds,
            amounts,
            tokenType,
            id(_uri),
          ],
        });
  
        const txResponse = await writeContract(config);
        const txReceipt = await txResponse.wait();
        const { logs } = txReceipt;
        const txReceiptLogs = txReceipt?.logs;

        let safeBatchTransferAmount;
        if (txReceiptLogs && txReceiptLogs?.length > 1) {
          for (const log of logs) {
            const eventHashString = ifaceProba.getEventTopic("TradableCertificateAllocated");
            if (eventHashString === log.topics[0]) {
              finalTokenId = BigNumber.from(log.topics[1]).toString();
              const cleanHex = log?.data?.slice(2);
              safeBatchTransferAmount = [parseInt(cleanHex.slice(64, 128), 16)];
              break;
            }
          }
        }

        if (payload?.tokenType === "PTCB") {
          const allocateMintedBcPayload = await prepareWriteContract({
            address: YOUR_PROBA_CONTRACT_ADDRESS,
            abi: YOUR_PROBA_CONTRACT_ADDRESS_ABI,
            functionName: "safeBatchTransferFrom",
            args: [
              userBcAddress,
              toUser?.baddress,
              [finalTokenId],
              safeBatchTransferAmount,
              id(""),
            ],
          });
  
          const txMintedResponse = await writeContract(allocateMintedBcPayload);
          const txMintedReceipt = await txMintedResponse.wait();
        }
      }
      else {
        const bcPayload = {
          contractAddress: YOUR_PROBA_CONTRACT_ADDRESS,
          functionName: createAllocateAction.name,
          functionArguments: [
            toUser?.baddress,
            tokenIds,
            amounts,
            tokenType,
            id(_uri),
          ],
          waitOnReceipt: true,
        }

        const txResponse = await sendBlockChainRequest(bcPayload).unwrap();
        const { logs } = txResponse;
        const txReceiptLogs = txResponse?.logs;
        let safeBatchTransferAmount;
        if (txReceiptLogs && txReceiptLogs?.length > 1) {
          for (const log of logs) {
            const eventHashString = ifaceProba.getEventTopic("TradableCertificateAllocated");
            if (eventHashString === log.topics[0]) {
              finalTokenId = BigNumber.from(log.topics[1]).toString();
              const cleanHex = log?.data?.slice(2);
              safeBatchTransferAmount = [parseInt(cleanHex.slice(64, 128), 16)];
              break;
            }
          }
        }

      if (payload?.tokenType === "PTCB") {
        const allocateMintedBcPayload = {
          contractAddress: YOUR_PROBA_CONTRACT_ADDRESS,
          functionName: "safeBatchTransferFrom",
          functionArguments: [
            userBcAddress,
            toUser?.baddress,
            [finalTokenId],
            safeBatchTransferAmount,
            id(""),
          ],
          waitOnReceipt: true,
        }

        const txMintedResponse = await sendBlockChainRequest(allocateMintedBcPayload).unwrap();
      }
    }

      if (flag === "EntitlementRecords") {
        try {
          const entitlementRecordsToUpdate = [];
          const entitlementRecordsToAdd = [];
          for (
            let i = 0;
            i < payload?.entitlementRecordsToTransfer?.length;
            i++
          ) {
            let entitlementRecord = {
              ...payload?.entitlementRecordsToTransfer[i],
            };
            let createEntitlementRecord = {};
            // only for full transfer
            if (entitlementRecord?.updated_no_of_entitlement_record === 0) {
              entitlementRecord.from_org = entitlementRecord?.organization_id;
              entitlementRecord.organization_id = payload?.to;
              entitlementRecord.to_org = payload?.to;
              entitlementRecord.no_of_entitlement_record =
                entitlementRecord?.no_of_entitlement_record;
            } else {
              createEntitlementRecord.from_org =
                entitlementRecord?.organization_id;
              createEntitlementRecord.to_org = payload?.to;
              createEntitlementRecord.organization_id = payload?.to;
              createEntitlementRecord.yield_id = entitlementRecord?.yield_id;
              createEntitlementRecord.status = entitlementRecord?.status;
              createEntitlementRecord.final_tradable_cert_uuid =
                entitlementRecord?.final_tradable_cert_uuid;
              createEntitlementRecord.is_updated_to_blockchain =
                entitlementRecord?.is_updated_to_blockchain;
              createEntitlementRecord.no_of_entitlement_record =
                entitlementRecord?.no_of_entitlement_record -
                entitlementRecord?.updated_no_of_entitlement_record;
              entitlementRecordsToAdd.push(createEntitlementRecord);
              entitlementRecord.no_of_entitlement_record =
              entitlementRecord?.updated_no_of_entitlement_record;
            }
            entitlementRecordsToUpdate.push(entitlementRecord);
          }
          await addPreEntitlementRecord(entitlementRecordsToAdd);
          await updatePreEntitlementRecords(entitlementRecordsToUpdate);

          generateMessageFromStatus({
            status: "success",
            message: "Token successfully Allocated ",
          });
        } catch (error) {
          console.error(error);
          displayErrorMessage(error);
          throw(error)
        }
      } else {
        if (finalTokenId) {
          const finalTokenRecordsToUpdate = [];
          const record = {
            no_of_token: payload?.transferAmount, // transferAmount
            finaltoken_id: finalTokenId, // created
            finaltoken_name: "PTCB",
            // owner_id: payload?.to,
            organization_id: payload?.to,
            type: payload?.finalCertificate[0]?.type,
            status: payload?.finalCertificate[0]?.status, //"active"
            metadata_uri: finalCertificateMetaDataUri,
            final_certificate_json: finalcertificateJsonPreviewData,
          };

          const { data, isLoading } = await store.dispatch(
            finaltokApi.endpoints.addFinalToken.initiate([record])
          );

          if (!isLoading) {
            const batchPayload = payload?.yieldDetails?.map((item) => ({
              entitlement_record_uuid: item.entitlement_record_uuid,
              credit_bundle_uuid: data?.data?.[0]?._id,
              no_of_credits:
                item.updated_no_of_credits !== undefined
                  ? item.updated_no_of_credits
                  : item.no_of_credits,
            }));

            await addEntitlementCreditBundle(batchPayload);
          }

          const getUpdatedYields = (
            finaltoken_name,
            finaltoken_id,
            assetName,
            yieldDetails
          ) => {
            return yieldDetails
              .filter(
                (detail) =>
                  detail.asset_name === assetName &&
                  detail.finaltoken_name === finaltoken_name &&
                  detail.finaltoken_id === finaltoken_id
              )
              .map((detail) => ({
                yield_period: detail?.yield_period,
                yield_storage_duration: detail?.yield_storage_duration,
                yield_co2_realized:
                  detail.updated_no_of_credits !== undefined
                    ? detail.no_of_credits - detail.updated_no_of_credits
                    : 0,
              }))?.filter((item)=> item?.yield_co2_realized !== 0)
          };

          for (let i = 0; i < payload?.finalCertificate?.length; i++) {
            let finalRecordData = { ...payload?.finalCertificate[i] };
            //Update the final certificate existing records
            if (finalRecordData?.updated_no_of_token === 0) {
              finalRecordData.no_of_token =
                finalRecordData?.updated_no_of_token;
              finalRecordData.status = "retired";
            } else {
              const jsonData =
                typeof payload.finalCertificate[i].final_certificate_json ===
                "string"
                  ? JSON.parse(
                      payload.finalCertificate[i].final_certificate_json
                    )
                  : payload.finalCertificate[i].final_certificate_json?.data ||
                    payload.finalCertificate[i].final_certificate_json;
              // Update the amount_of_tradable_certificates with remaining number of token amount
              const updatedFinalCertificateJsonData = jsonData.data
                ? {
                    ...jsonData,
                    data: [
                      {
                        ...jsonData.data[0],
                        amount_of_tradable_certificates:
                          finalRecordData?.updated_no_of_token,
                      },
                    ],
                  }
                : {
                    ...jsonData,
                    amount_of_tradable_certificates:
                      finalRecordData?.updated_no_of_token,
                  };

              if (
                updatedFinalCertificateJsonData.data &&
                updatedFinalCertificateJsonData.data.length > 0
              ) {
                const assets =
                  updatedFinalCertificateJsonData.data[0].assets || [];
                for (let asset of assets) {
                  // Get updated yields using the assetName and yieldDetails
                  asset.yields = getUpdatedYields(
                    finalRecordData.finaltoken_name,
                    finalRecordData?.finaltoken_id,
                    asset.asset_name,
                    payload.yieldDetails
                  );
                }
              }

              finalRecordData.final_certificate_json = JSON.stringify(
                updatedFinalCertificateJsonData
              );
              finalRecordData.no_of_token =
                finalRecordData?.updated_no_of_token;
            }
            finalTokenRecordsToUpdate.push(finalRecordData);
          }
          const updatedYieldDetails = payload?.yieldDetails.map((item) => ({
            uuid: item._id,
            no_of_credits:
              item.updated_no_of_credits !== undefined
                ? Number(
                    (item.no_of_credits - item.updated_no_of_credits).toFixed(4)
                  )
                : 0,
          }));

          await updateEntitlementCreditBundles(updatedYieldDetails).unwrap();
          await updateFinalTokenRecords(finalTokenRecordsToUpdate);
        } else {
          try {
            const finalTokenRecordsToAdd = [];
            const finalTokenRecordsToUpdate = [];
            for (let i = 0; i < payload?.finalCertificate?.length; i++) {
              let finalRecordData = { ...payload?.finalCertificate[i] };
              let createFinalRecordData = {};
              //only for full transfer
              if (finalRecordData?.updated_no_of_token === 0) {
                finalRecordData.organization_id = payload?.to;
              } else {
                createFinalRecordData.organization_id = payload?.to;
                createFinalRecordData.no_of_token =
                  finalRecordData?.no_of_token -
                  finalRecordData?.updated_no_of_token;
                createFinalRecordData.finaltoken_id = "finalTokenId"; //TODO Update the values from BC
                createFinalRecordData.finaltoken_name = "PTCB";
                createFinalRecordData.type = finalRecordData?.type;
                createFinalRecordData.status = finalRecordData?.status;
                createFinalRecordData.metadata_uri =
                  finalRecordData?.metadata_uri;
                const jsonData =
                  typeof payload.finalCertificate[i].final_certificate_json ===
                  "string"
                    ? JSON.parse(
                        payload.finalCertificate[i].final_certificate_json
                      )
                    : payload.finalCertificate[i].final_certificate_json
                        ?.data ||
                      payload.finalCertificate[i].final_certificate_json;
                // Update the amount_of_tradable_certificates with user inputted transfer amount
                const createFinalCertificateJsonData = jsonData.data
                  ? {
                      ...jsonData,
                      data: [
                        {
                          ...jsonData.data[0],
                          amount_of_tradable_certificates:
                            payload?.transferAmount,
                        },
                      ],
                    }
                  : {
                      ...jsonData,
                      amount_of_tradable_certificates: payload?.transferAmount,
                    };

                createFinalRecordData.final_certificate_json = JSON.stringify(
                  createFinalCertificateJsonData
                );
                finalTokenRecordsToAdd.push(createFinalRecordData);
                await addFinalTokenRecord(finalTokenRecordsToAdd);
                // Update the amount_of_tradable_certificates with remaining number of token amount
                const updatedFinalCertificateJsonData = jsonData.data
                  ? {
                      ...jsonData,
                      data: [
                        {
                          ...jsonData.data[0],
                          amount_of_tradable_certificates:
                            finalRecordData?.updated_no_of_token,
                        },
                      ],
                    }
                  : {
                      ...jsonData,
                      amount_of_tradable_certificates:
                        finalRecordData?.updated_no_of_token,
                    };

                finalRecordData.final_certificate_json = JSON.stringify(
                  updatedFinalCertificateJsonData
                );
                finalRecordData.no_of_token =
                  finalRecordData?.updated_no_of_token;
              }
              finalTokenRecordsToUpdate.push(finalRecordData);
            }
            await updateFinalTokenRecords(finalTokenRecordsToUpdate);
          } catch (error) {
            console.error(error);
            displayErrorMessage(error);
            throw(error)
          }
        }

        generateMessageFromStatus({
          status: "success",
          message: "Token successfully Allocated ",
        });
        return true;
      }
    } catch (error) {
      console.error(error);
      displayErrorMessage(error);
      generateMessageFromStatus({
        status: "error",
        message: error.data ,
      });
      return false;
    }
  };

  return { createAllocateSubmit };
};

export const useCreateClaimHandlers = ({ transaction }) => {
  const { address: account, isConnected } = useAccount();
  const userOwnWalletConnect = JSON.parse(localStorage.getItem("user"))?.useownwallet;
  const [sendBlockChainRequest] = useSendBlockChainRequestMutation();
  const [updateFinalTokenRecords] = useUpdateFinalTokenRecordsMutation();
  const [addFinalTokenRecord] = useAddFinalTokenMutation();
  const [
    updateEntitlementCreditBundles,
  ] = useUpdateEntitlementCreditBundlesMutation();
  const [addEntitlementCreditBundle] = useAddEntitlementCreditBundleMutation();
  const userid = useSelector((state) => state.auth.userid);
  const organization_id = useSelector((state) => state.auth?.org?._id);


  const getUpdatedYields = (
    finaltoken_name,
    finaltoken_id,
    assetName,
    yieldDetails
  ) => {
    return yieldDetails
      .filter(
        (detail) =>
          detail.asset_name === assetName &&
          detail.finaltoken_name === finaltoken_name &&
          detail.finaltoken_id === finaltoken_id &&
          (detail.updated_no_of_credits === undefined ||
            detail.updated_no_of_credits > 0)
      )
      ?.map((detail) => ({
        yield_co2_realized:
          detail.updated_no_of_credits !== undefined
            ? detail.updated_no_of_credits
            : detail.no_of_credits,
        yield_period: detail?.yield_period,
        yield_storage_duration: detail?.yield_storage_duration,
      }));
  };

  const getActiveUpdatedYields = (
    finaltoken_name,
    finaltoken_id,
    assetName,
    yieldDetails,
    updated_no_of_token
  ) => {
    if (updated_no_of_token !== 0) {
      return yieldDetails
        .filter(
          (detail) =>
            detail.asset_name === assetName &&
            detail.finaltoken_name === finaltoken_name &&
            detail.finaltoken_id === finaltoken_id
        )
        .map((detail) => ({
          yield_co2_realized:
            detail.updated_no_of_credits !== undefined
              ? detail.no_of_credits - detail.updated_no_of_credits
              : 0,
          yield_period: detail?.yield_period,
          yield_storage_duration: detail?.yield_storage_duration,
        }))
        .filter(item => item?.yield_co2_realized !== 0);
    } else {
      return yieldDetails
        .filter(
          (detail) =>
            detail.asset_name === assetName &&
            detail.finaltoken_name === finaltoken_name &&
            detail.finaltoken_id === finaltoken_id
        )
        .map((detail) => ({
          yield_co2_realized: detail.no_of_credits, 
          yield_period: detail?.yield_period,
          yield_storage_duration: detail?.yield_storage_duration,
        }));
    }
  };

  const updateClaimedRange = (claimedRange, noOfToken) => {
    if (!claimedRange || claimedRange?.length==0) {
      return [[0, noOfToken]];
    } else {
      const lastClaimed = claimedRange[claimedRange.length - 1];
      if (lastClaimed[1] === noOfToken) {
        return claimedRange;
      } else {
        return [...claimedRange, [lastClaimed[1], lastClaimed[1]+noOfToken]];
      }
    }
  };
  
  

  const createClaimSubmit = async (payload) => {
    if (!isConnected && userOwnWalletConnect) {
      message.info({
        content: "Please Connect A Wallet",
        key: "updatable",
        duration: 2,
      });
      return false;
    }
    let tokenIds = [];
    let amounts = [];

    const finalRecords = payload?.finalCertificate;
    for (let i = 0; i < finalRecords?.length; i++) {
      const record = finalRecords?.[i];
      const tokenId = parseInt(record?.finaltoken_id);
      const amount = record?.no_of_token - record?.updated_no_of_token;
      const tokenType = record?.finaltoken_name;
      tokenIds.push(tokenId);
      amounts.push(Math.round(amount * 10000));
    }
   
    try {
      if (userOwnWalletConnect) {
        const config = await prepareWriteContract({
          address: YOUR_PROBA_CONTRACT_ADDRESS,
          abi: YOUR_PROBA_CONTRACT_ADDRESS_ABI,
          functionName: createClaimAction.name,
          args: [tokenIds, amounts],
        });
  
        const txResponse = await writeContract(config);
        const txReceipt = await txResponse.wait();
        const { logs } = txReceipt;
      }
      else {
        const bcPayload = {
          contractAddress: YOUR_PROBA_CONTRACT_ADDRESS,
          functionName: createClaimAction.name,
          functionArguments: [tokenIds, amounts],
          waitOnReceipt: true,
        }
  
        const txResponse = await sendBlockChainRequest(bcPayload).unwrap();
        const { logs } = txResponse;
      }

      try {
        debugger
        const finalTokenRecordsToAdd = [];
        const finalTokenRecordsToUpdate = [];
        for (let i = 0; i < payload?.finalCertificate?.length; i++) {
          let finalRecordData = { ...payload?.finalCertificate?.[i] };
          let totalYieldCO2Realized = 0;
          let createFinalRecordData = {};
          //only for full claim
          if (finalRecordData?.updated_no_of_token === 0) {
            finalRecordData.certificate_retirement_purpose =
              payload?.retirementPurpose;
            finalRecordData.beneficiary_name = payload?.beneficiaryName;
            finalRecordData.status = "retired";
          } else {
            // createFinalRecordData.owner_id = userid;
            createFinalRecordData.organization_id = organization_id;
            createFinalRecordData.no_of_token =
              finalRecordData?.no_of_token -
              finalRecordData?.updated_no_of_token;
            createFinalRecordData.finaltoken_id =
              finalRecordData?.finaltoken_id;
            createFinalRecordData.finaltoken_name = "PTCB";
            createFinalRecordData.type = finalRecordData?.type;
            createFinalRecordData.status = "retired";
            createFinalRecordData.metadata_uri = finalRecordData?.metadata_uri;
            createFinalRecordData.certificate_retirement_purpose =
              payload?.retirementPurpose;
            createFinalRecordData.beneficiary_name = payload?.beneficiaryName;
            createFinalRecordData.final_certificate_json =
              finalRecordData?.final_certificate_json;
            finalRecordData.no_of_token = finalRecordData?.updated_no_of_token;
            const jsonData =
              typeof finalRecordData.final_certificate_json === "string"
                ? JSON.parse(finalRecordData.final_certificate_json)
                : finalRecordData.final_certificate_json?.data ||
                  finalRecordData.final_certificate_json;

            if (jsonData.data && jsonData.data.length > 0) {
              const assets = jsonData.data[0].assets || [];
              for (let asset of assets) {
                const updatedYields = getUpdatedYields(
                  createFinalRecordData.finaltoken_name,
                  finalRecordData?.finaltoken_id,
                  asset.asset_name,
                  payload.yieldDetails
                );
                asset.yields = updatedYields;
                for (const yieldItem of updatedYields) {
                  totalYieldCO2Realized += yieldItem.yield_co2_realized;
                }
              }

              const updatedFinalCertificateJsonData = jsonData.data
                ? {
                    ...jsonData,
                    data: [
                      {
                        ...jsonData.data[0],
                        amount_of_tradable_certificates: totalYieldCO2Realized,
                      },
                    ],
                  }
                : {
                    ...jsonData,
                    amount_of_tradable_certificates: totalYieldCO2Realized,
                  };

              createFinalRecordData.final_certificate_json = JSON.stringify(
                updatedFinalCertificateJsonData
              );
            }

            finalTokenRecordsToAdd.push(createFinalRecordData);
          }

          if (finalRecordData.final_certificate_json) {
            totalYieldCO2Realized = 0;
            const jsonData =
              typeof finalRecordData.final_certificate_json === "string"
                ? JSON.parse(finalRecordData.final_certificate_json)
                : finalRecordData.final_certificate_json?.data ||
                  finalRecordData.final_certificate_json;

            if (jsonData.data && jsonData.data.length > 0) {
              const assets = jsonData.data[0].assets || [];
              for (let asset of assets) {
                const updatedYields = getActiveUpdatedYields(
                  finalRecordData.finaltoken_name,
                  finalRecordData?.finaltoken_id,
                  asset.asset_name,
                  payload.yieldDetails,
                  finalRecordData?.updated_no_of_token
                );
                asset.yields = updatedYields;
                for (const yieldItem of updatedYields) {
                  totalYieldCO2Realized += yieldItem.yield_co2_realized;
                }
              }
             
              jsonData.amount_of_tradable_certificates = totalYieldCO2Realized;

              const updatedFinalCertificateJsonData = jsonData.data
                ? {
                    ...jsonData,
                    data: [
                      {
                        ...jsonData.data[0],
                        amount_of_tradable_certificates: totalYieldCO2Realized,
                      },
                    ],
                  }
                : {
                    ...jsonData,
                    amount_of_tradable_certificates: totalYieldCO2Realized,
                  };
              finalRecordData.final_certificate_json = JSON.stringify(
                updatedFinalCertificateJsonData
              );
            }
          }

          finalTokenRecordsToUpdate.push(finalRecordData);
        }
        const claimedRecord=await addFinalTokenRecord(finalTokenRecordsToAdd);
     
        const updatedYieldDetails = payload?.yieldDetails.map((item) => {
          // Update the claimed_range using item instead of finalRecordData
          const claimedRange = updateClaimedRange(
            item.claimed_range, 
            item.updated_no_of_credits !== undefined ? item.updated_no_of_credits : item?.no_of_credits
          );
        
          return {
            uuid: item._id,
            claimed_range: [claimedRange?.[claimedRange?.length - 1]],
            no_of_credits: 
              item.updated_no_of_credits !== undefined 
                ? Number((item.no_of_credits - item.updated_no_of_credits).toFixed(4)) 
                : 0,
          };
        });

        const claimedYieldDetails = payload?.yieldDetails?.filter((item) => (item.updated_no_of_credits !== undefined && item.updated_no_of_credits !==0) )?.map((item) => {
            const claimedRange = updateClaimedRange(
              item.claimed_range, 
              item.updated_no_of_credits
            );
           
            return {
              credit_bundle_uuid: claimedRecord?.data?.data?.[0]?._id,
              entitlement_record_uuid: item?.entitlement_record_uuid,
              claimed_range: [claimedRange?.[claimedRange?.length - 1]],
              no_of_credits: Number(item.updated_no_of_credits)?.toFixed(4),
            };
          });

        await addEntitlementCreditBundle(claimedYieldDetails)
        await updateEntitlementCreditBundles(updatedYieldDetails).unwrap();
        await updateFinalTokenRecords(finalTokenRecordsToUpdate);
      } catch (error) {
        console.error(error);
      }
      generateMessageFromStatus({
        status: "success",
        message: "Token successfully Claimed",
      });
      return true;
    } catch (error) {
      console.error(error);
      displayErrorMessage(error);
      return false;
    }
  };

  return { createClaimSubmit };
};

// cancelling Pre-credits

export const useCreateCancelPreCreditsSubmitHandlers = ({ transaction }) => {
  const [sendBlockChainRequest] = useSendBlockChainRequestMutation();

  const [
    updatePreEntitlementRecords,
  ] = useUpdatePreEntitlementRecordsMutation();

  const createCancelPreCreditsSubmit = async (payload) => {
    try {
      //dummy data
      let tokenIds = "0";
      let amounts = [];
      const toUser = await getUser(payload?.to);
      const bcPayload = {
        contractAddress: YOUR_PROBA_CONTRACT_ADDRESS,
        functionName: createCancelPreCreditsAction.name,
        functionArguments: [
          payload?.from.baddress,
          toUser?.baddress,
          tokenIds,
          amounts,
        ],
      };
      const txResponse = await sendBlockChainRequest(bcPayload).unwrap();
      generateMessageFromStatus({
        status: "success",
        message: "Cancellation request for precredits submitted successfully!",
      });

      await updatePreEntitlementRecords(payload)
        .unwrap()
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });

      return true;
    } catch (error) {
      console.error(error);
      displayErrorMessage(error);
      return false;
    }
  };

  return { createCancelPreCreditsSubmit };
};

export const useAcceptCancelOfPreCreditsSubmitHandlers = ({ transaction }) => {
  const [sendBlockChainRequest] = useSendBlockChainRequestMutation();

  const [
    updatePreEntitlementRecords,
  ] = useUpdatePreEntitlementRecordsMutation();

  const acceptCancelOfPreCreditsSubmit = async (payload) => {
    try {
      //dummy data
      let tokenIds = "0";
      let amounts = [];
      const toUser = await getUser(payload?.to);

      const bcPayload = {
        contractAddress: YOUR_PROBA_CONTRACT_ADDRESS,
        functionName: createAllocateAction.name,
        functionArguments: [
          payload?.from.baddress,
          toUser?.baddress,
          tokenIds,
          amounts,
        ],
      };

      const txResponse = await sendBlockChainRequest(bcPayload).unwrap();
      generateMessageFromStatus({
        status: "success",
        message: "Accepted request for cancelling precredits!",
      });

      await updatePreEntitlementRecords(payload)
        .unwrap()
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });

      return true;
    } catch (error) {
      console.error(error);
      displayErrorMessage(error);
      return false;
    }
  };

  return { acceptCancelOfPreCreditsSubmit };
};
